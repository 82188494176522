"use strict";

$(window).resize(function() {
  if($(window).width() > 992) {
    $('#first-number-offset').css('margin-top', ($('#first-number').height() / 2) + $('#h2-height').height() + 120);
  } else {
   // $('#first-number-offset').css('margin-top', $('#h2-height').height() + 90);
  }

  if($(window).width() < 1200) {
    $('#first-number-offset').css('margin-top', 0);
  }

  if($(window).width() < 992){
    $('.tab-wrapper').css('bottom', (-1 * $('.tab-wrapper').height()) + 100);
    $('.packet').css('padding-top', ($('.tab-wrapper').height() - 100));
  } else {
    $('.tab-wrapper').css('bottom', -30);
    $('.packet').css('padding-top', 0);
  }

});

var textOrphans = function() {
  $('p').each(function() {
    if ($(this).html().match(/class="order"/g)) return;
    var tekst = $(this).html();
    tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
    tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
    $(this).html(tekst);
  });
};

var animations = function() {
  $('.input-wrapper').on('click',function(){
    $(this).find('.placeholder').addClass('active');
  });

  $('.input-wrapper').on('focusout', function () {
    if(!$(this).find('input').val()){
      $(this).find('.placeholder').removeClass('active');
    }
  });
};

var numbers = function() {

  if($(window).width() > 992) {
    $('#first-number-offset').css('margin-top', ($('#first-number').height() / 2) + $('#h2-height').height() + 120);
  } else {
    //$('#first-number-offset').css('margin-top', $('#h2-height').height() + 90);
  }

  if($(window).width() < 1200) {
    $('#first-number-offset').css('margin-top', 0);
  }

  if($(window).width() < 992){
    $('.tab-wrapper').css('bottom', (-1 * $('.tab-wrapper').height()) + 100);
    $('.packet').css('padding-top', ($('.tab-wrapper').height() - 100));
  } else {
    $('.tab-wrapper').css('bottom', -30);
    $('.packet').css('padding-top', 0);
  }

};

var topMovie = function(){


  var counter = 1;
  var headers = $('.description-section .description-top > div');
  var descriptions = $('.description-section .description-text > div');

  function changeSlide(){
    doSetTimeout();
    // headers.removeClass('active');
    // descriptions.removeClass('active');

    headers.each(function(index){
      $(this).removeClass('active');
      if($(this).data('header') == counter){
        $(this).addClass('active');
      }
    });

    descriptions.each(function(index){
      $(this).removeClass('active');
      if($(this).data('description') == counter){
        $(this).addClass('active');
      }
    });

    if(counter < 3){
      counter++;
    } else {
      counter = 1;
    }
  }

  function doSetTimeout(){
    setTimeout(function(){
      changeSlide();
    }, 4500);
  }

  doSetTimeout();

};

var mainMenu = function() {
  $('.hamburger').click(function(){

    if($(this).hasClass('is-active')){
      $(this).removeClass('is-active');
      $('body').removeClass('show-menu');
    } else {
      $(this).addClass('is-active');
      $('body').addClass('show-menu');
    }
  })
};

var slider = function() {
  $('.slick-mobile').slick({
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 5000,
  });
};

var scrollMagic = function() {


  var offset = 0;

  if($('.packet').length > 0) {
    offset = -100;
  }

  if($('.brands').length > 0) {

    if(($(window).width() < 992)){
      offset = -80;
    } else {
      offset = -140;
    }
  }

  $(function(){
    $.scrollIt({
      upKey: 38,             // key code to navigate to the next section
      downKey: 40,           // key code to navigate to the previous section
      easing: 'linear',      // the easing function for animation
      scrollTime: 600,       // how long (in ms) the animation takes
      activeClass: 'active', // class given to the active nav element
      onPageChange: null,    // function(pageIndex) that is called when page is changed
      topOffset: offset           // offste (in px) for fixed top navigation
    });
  });



  // set main controler
  var controller = new ScrollMagic.Controller();


  // show or hide fixed menu
  var fixedMainMenu =  new ScrollMagic.Scene({
    triggerHook: '0',
    offset: 150,
    reverse: true
  })
  .on('start', function () {
    $('.menu-section').addClass('fixed').fadeIn(1000);
  })
  .on('leave', function () {
    $('.menu-section').removeClass('fixed');
  })
  .addTo(controller);


  function counterAnimation(){

    const options = {
      separator: ' ',
      duration: 4
    };
    let number1 = new CountUp('code1', 138000000, options);
    number1.start();

    let number2 = new CountUp('code2', 38500000, options);
    number2.start();

    let number3 = new CountUp('code3', 12000, options);
    number3.start();

    let number4 = new CountUp('code4', 676, options);
    number4.start();

    let number5 = new CountUp('code5', 36, options);
    number5.start();

  }

  //numbers page animations

    new ScrollMagic.Scene({
      triggerElement: '.numbers',
      triggerHook: '.4',
      offset: 0,
      reverse: false
    })
    .setClassToggle(this ,'counter')
    .on('enter', function(e){
      counterAnimation();
    })
    .addTo(controller);


  // industrial image animation

    var industrialAnimation =  new ScrollMagic.Scene({
      triggerElement: '.industrial',
      triggerHook: '0.7',
      offset: 0,
      reverse: false
    })
        .setClassToggle('.industrial1','start')
        .addTo(controller);


  // industrial logos animation

  var industrialLogosAnimation =  new ScrollMagic.Scene({
    triggerElement: '.industrial-container',
    triggerHook: '0.9',
    offset: 0,
    reverse: false
  })
      .setClassToggle('.logo-grid','start')
      .addTo(controller);

};

var cookies = function () {
  if ($.cookie('giodoCookies') !== '1') {
    $('.cookies-alert').css('display', 'block');
  }
  $('.cookies-alert button').on('click', function () {
    $('.cookies-alert').slideToggle('slow', function () {
      $.cookie('giodoCookies', '1', { expires: 365 });
    });
  });
};



$(function () {
  // animations();
  // numbers();
  // mainMenu();
  // topMovie();
  // textOrphans();
  // scrollMagic();
  // slider();
  // cookies();
});